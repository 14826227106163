import React, { useEffect, useState } from 'react'
import { GlobalClient, Entity, File as FileEntity } from 'drupal-jsonapi-client'
import { DrupalImage } from 'react-drupal'
import idx from 'idx'
import defaultImage from '../../../static/sailboat.svg'
import '../DrupalImage/styles.css'
import './styles.css'
import Seo from '../Seo'

GlobalClient.baseUrl = 'https://dev-skillsailors.pantheonsite.io'

const UserProfile = ({ jwt, uuid }) => {
  const [updatingProfile, setUpdatingProfile] = useState(false)
  const [message, setMessage] = useState()
  const [userImage, setUserImage] = useState(null)
  const [user, setUserEntity] = useState(null)

  const [form, setForm] = useState({})

  useEffect(() => {
    ;(async () => {
      const userEntityRequest = await Entity.Load('user', 'user', uuid, [
        'field_user_profile',
        'field_user_profile.field_user_profile_image',
      ])

      try {
        const userProfile = await userEntityRequest.expand('field_user_profile')
        setUserEntity({ userEntity: userProfile, userEntityRequest })

        const form = {
          body: idx(userProfile, _ => _.body.value || ''),
          name: idx(userEntityRequest, _ => _.name),
          field_user_linkedin: idx(
            userEntityRequest,
            _ => _.field_user_linkedin.uri
          ),
          field_user_twitter: idx(
            userEntityRequest,
            _ => _.field_user_twitter.uri
          ),
          field_user_instagram: idx(
            userEntityRequest,
            _ => _.field_user_instagram.uri
          ),
          field_user_languages: idx(
            userEntityRequest,
            _ => _.field_user_languages
          ),
        }
        setForm(form)
        const userProfilePicture = await userProfile.expand(
          'field_user_profile_image'
        )
        setUserImage({
          id: userProfilePicture.entityUuid,
          name: userProfilePicture.filename,
          url: `${GlobalClient.baseUrl}${userProfilePicture.uri.url}`,
        })
      } catch (err) {}
    })()
  }, [jwt, uuid])

  const handleSubmit = async e => {
    e.preventDefault()
    setUpdatingProfile(true)
    const { userEntity, userEntityRequest } = user
    if (form.name !== userEntityRequest.name) {
      userEntityRequest.setAttribute('name', form.name)
    }

    userEntity.setAttribute('body', {
      value: form.body,
      format: 'basic_html',
    })
    userEntityRequest.setAttribute('field_user_instagram', {
      title: 'Instragram',
      uri: form.field_user_instagram,
    })
    userEntityRequest.setAttribute('field_user_twitter', {
      title: 'Twitter',
      uri: form.field_user_twitter,
    })
    userEntityRequest.setAttribute('field_user_linkedin', {
      title: 'linkedin',
      uri: form.field_user_linkedin,
    })
    userEntityRequest.setAttribute(
      'field_user_languages',
      form.field_user_languages
    )
    try {
      await userEntityRequest.save()
      await userEntity.save()
      setUserEntity({ userEntity, userEntityRequest })
      setUpdatingProfile(false)
      setMessage({ type: 'success', text: 'Updated user Profile' })
    } catch {
      setMessage({ type: 'error', text: 'An error occurred, try again later.' })
      setUpdatingProfile(false)
    }
  }

  const handleChange = evt => {
    const { name, value } = evt.target
    setForm({ ...form, [name]: value })
  }

  return (
    <div className="w-full flex justify-center">
      <Seo title="Edit Profile" />
      <div className="container mt-4">
        <h1 className="font-bold text-2xl md:hidden">Your Profile</h1>
        {message && (
          <div
            className={`w-full px-2 py-4 flex mt-2
              ${message.type === 'success' ? 'bg-green-200' : 'bg-red-200'}
            `}
          >
            <p className="font-bold w-11/12">{message.text}</p>
          </div>
        )}
        <div className="flex flex-col md:flex-row">
          <div className="w-full profile md:w-1/3 flex flex-col justify-start items-center content-center">
            {!userImage ? (
              <figure className="flex justify-center">
                <img
                  alt="user profile"
                  className="w-32 p-1"
                  src={defaultImage}
                />
              </figure>
            ) : (
              <DrupalImage
                images={[userImage].filter(image => !!image)}
                onDelete={async id => {
                  if (!id) return
                  const { userEntity } = user
                  userEntity.field_user_profile_image = { data: null }
                  await userEntity.save()
                  await FileEntity.Delete(id)
                  setUserEntity({ ...user, userEntity })
                  setUserImage(null)
                }}
                onUpload={async image => {
                  const file = await FileEntity.Upload(
                    image,
                    null,
                    'node',
                    'user_profile',
                    'field_user_profile_image'
                  )
                  const { userEntity } = user
                  userEntity.field_user_profile_image = file
                  await userEntity.save()
                  setUserEntity({ ...user, userEntity })
                  setUserImage({
                    id: file.entityUuid,
                    name: file.filename,
                    url: `${GlobalClient.baseUrl}${file.uri.url}`,
                  })
                }}
              />
            )}
          </div>
          <div className="w-full">
            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="name"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                Name
              </label>
              <input
                placeholder="Your name"
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={handleChange}
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-purple-1"
              />
              {user &&
                user.userEntityRequest &&
                (user.userEntityRequest.name !== form.name ? (
                  <p className="py-2 font-bold text-red-500">
                    This is your username, with which you log in.
                  </p>
                ) : null)}
            </div>
            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="language"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                Languages
              </label>
              <input
                placeholder="English, Spanish, Deutsch"
                type="text"
                id="language"
                name="field_user_languages"
                value={form.field_user_languages}
                onChange={handleChange}
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="instagram"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                Instagram
              </label>
              <input
                placeholder="https://instagram.com/skillsailors"
                type="text"
                id="instagram"
                name="field_user_instagram"
                value={form.field_user_instagram}
                onChange={handleChange}
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="twitter"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                Twitter
              </label>
              <input
                type="text"
                id="twitter"
                name="field_user_twitter"
                value={form.field_user_twitter}
                onChange={handleChange}
                placeholder="https://twitter.com/skillsailors"
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="linkedin"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                Linkedin
              </label>
              <input
                type="text"
                id="linkedin"
                name="field_user_linkedin"
                value={form.field_user_linkedin}
                onChange={handleChange}
                placeholder="https://linkedin.com/in/skillsailors"
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-6">
              <label
                htmlFor="body"
                className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
              >
                About me
              </label>
              <textarea
                className="appearance-none block w-full text-gray-700 border-2 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                id="body"
                name="body"
                value={form.body}
                onChange={handleChange}
                cols="45"
                rows="10"
                placeholder="What would you like sailors to know about you?"
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-6">
              <button
                className="w-full bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 px-4 border border-green-1 hover:border-transparent rounded md:w-auto"
                onClick={handleSubmit}
              >
                {updatingProfile ? 'Updating profile...' : 'Update profile'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
