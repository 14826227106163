import { useEffect, useState } from 'react'
import { Entity, Filter } from 'drupal-jsonapi-client'

const usePendingRequestVoyage = ({ userId }) => {
  const [fetching, setFetching] = useState(true)
  const [pendingUsers, setPendingUsers] = useState([])

  useEffect(() => {
    ;(async () => {
      const voyages = await Entity.LoadMultiple({
        entityType: 'node',
        entityBundle: 'voyage',
        include: ['field_voyage_pending_users'],
        filter: new Filter({
          identifier: 'ids',
          path: 'uid.id',
          value: userId,
        }),
      })
      let voyageUserMap = {}

      for (let i = 0; i < voyages.length; i++) {
        const voyageId = voyages[i].entityUuid
        const user = await voyages[i].expand('field_voyage_pending_users')
        voyageUserMap[voyageId] = {
          voyage: voyages[i],
          users: user,
        }
      }

      setPendingUsers(voyageUserMap)
      setFetching(false)
    })()
  }, [userId])

  const acceptUser = async (voyageEntity, acceptedUserEntity) => {
    const latestsVoyageEntity = await Entity.Load(
      'node',
      'voyage',
      voyageEntity.entityUuid,
      ['field_voyage_pending_users', 'field_voyage_accepted_users'],
      true
    )
    const latestPendingUsers = await latestsVoyageEntity.expand(
      'field_voyage_pending_users'
    )
    const latestAcceptedUsers = await latestsVoyageEntity.expand(
      'field_voyage_accepted_users'
    )
    const updatedPendingUsers = latestPendingUsers.filter(
      pendingUser => pendingUser.entityUuid !== acceptedUserEntity.entityUuid
    )

    latestsVoyageEntity.field_voyage_pending_users = updatedPendingUsers
    latestsVoyageEntity.field_voyage_accepted_users = [
      ...latestAcceptedUsers,
      acceptedUserEntity,
    ]
    const updatedVoyageEntity = Entity.FromResponse(
      (await latestsVoyageEntity.save()).data.data
    )

    const updatedVoyageUserMap = { ...pendingUsers }
    updatedVoyageUserMap[updatedVoyageEntity.entityUuid] = {
      voyage: updatedVoyageEntity,
      users: updatedPendingUsers,
    }
    setPendingUsers(updatedVoyageUserMap)
  }

  const declineUser = async (voyageEntity, declinedUserEntity) => {
    const latestsVoyageEntity = await Entity.Load(
      'node',
      'voyage',
      voyageEntity.entityUuid,
      ['field_voyage_pending_users'],
      true
    )

    const latestPendingUsers = await latestsVoyageEntity.expand(
      'field_voyage_pending_users'
    )
    const updatedPendingUsers = latestPendingUsers.filter(
      pendingUser => pendingUser.entityUuid !== declinedUserEntity.entityUuid
    )

    latestsVoyageEntity.field_voyage_pending_users = updatedPendingUsers
    const updatedTripEntity = Entity.FromResponse(
      (await latestsVoyageEntity.save()).data.data
    )

    const updatedVoyageUserMap = { ...pendingUsers }
    updatedVoyageUserMap[updatedTripEntity.entityUuid] = {
      voyage: updatedTripEntity,
      users: updatedPendingUsers,
    }
    setPendingUsers(updatedVoyageUserMap)
  }

  return [fetching, pendingUsers, acceptUser, declineUser]
}

export default usePendingRequestVoyage
