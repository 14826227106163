import { useEffect, useState } from 'react'
import { Entity } from 'drupal-jsonapi-client'
import idx from 'idx'

const useCheckPendingRequestVoyage = ({ voyageID, userUUID }) => {
  const [userStatus, setuserStatus] = useState({
    isPending: false,
    isAccepted: false,
  })

  useEffect(() => {
    ;(async () => {
      if (userUUID && voyageID) {
        const voyage = await Entity.Load('node', 'voyage', voyageID)
        const pending_users =
          idx(voyage, _ => _._relationships.field_voyage_pending_users.data) ||
          []

        const accepted_users =
          idx(voyage, _ => _._relationships.field_voyage_accepted_users.data) ||
          []

        const isAccepted = accepted_users.some(({ id }) => id === userUUID)
        const isPending = pending_users.some(({ id }) => id === userUUID)
        setuserStatus({
          isAccepted,
          isPending,
        })
      }
    })()
  }, [voyageID, userUUID])
  return { userStatus }
}

export default useCheckPendingRequestVoyage
