import React, { useEffect, useState } from 'react'
import { Entity } from 'drupal-jsonapi-client'
import kebabCase from 'lodash.kebabcase'
import useCheckPendingRequestVoyage from '../../hooks/useCheckPendingRequestVoyage'

const JoinVoyage = ({ voyageID, uuid }) => {
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentVoyage, setCurrentVoyage] = useState({})

  const { userStatus } = useCheckPendingRequestVoyage({
    voyageID,
    userUUID: uuid,
  })

  useEffect(() => {
    ;(async () => {
      const voyage = await Entity.Load('node', 'voyage', voyageID)
      setCurrentVoyage(voyage)
      setLoading(false)
    })()
  }, [voyageID])

  const onSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)

    const formData = new FormData(event.target)

    const voyageSubmissionEntity = new Entity('node', 'voyage_submissions')
    voyageSubmissionEntity.setRelationship('field_voyage_submissions', {
      data: {
        type: 'node--voyage',
        id: voyageID,
      },
    })

    voyageSubmissionEntity.setAttribute('title', formData.get('title'))
    voyageSubmissionEntity.setAttribute('body', {
      value: formData.get('body'),
      format: 'basic_html',
    })

    await voyageSubmissionEntity.save()

    if (typeof window !== 'undefined') {
      window.location.href = `/voyage/${
        currentVoyage.drupal_internal__nid
      }/${kebabCase(currentVoyage.title)}`
    }
  }

  return (
    !loading && (
      <>
        {(userStatus.isAccepted || userStatus.isPending) && (
          <div className="w-full flex flex-col justify-center">
            <div className="container">
              <div className="p-8">
                <h1 className="text-center text-xl font-bold lg:text-3xl">
                  {userStatus.isPending
                    ? 'Thank you for subscribing, your application is in process.'
                    : 'You are already part of this Voyage!'}
                </h1>
              </div>
            </div>
          </div>
        )}

        {!userStatus.isAccepted && !userStatus.isPending && (
          <div className="w-full flex flex-col justify-center">
            <div className="container">
              <div className="p-8">
                <h1 className="text-center text-xl font-bold lg:text-3xl">
                  Join {currentVoyage.title}
                </h1>
              </div>
            </div>

            <div className="pt-4">
              <form onSubmit={onSubmit} className="w-full px-2 shadow-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-8 mb-6 md:mb-4 md:w-1/2">
                    <label
                      htmlFor="join-title"
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    >
                      Message Title
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                      type="text"
                      id="join-title"
                      name="title"
                      placeholder="Title"
                      required=""
                    />
                  </div>
                  <div className="w-full px-8 mb-6 md:mb-4">
                    <label
                      htmlFor="join-body"
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    >
                      Message:
                    </label>
                    <textarea
                      className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                      type="text"
                      id="join-body"
                      name="body"
                      placeholder="Message"
                      required=""
                      rows="5"
                    />
                  </div>
                  <div className="w-full px-8 mb-6 md:mb-4">
                    {!submitting ? (
                      <button
                        type="submit"
                        className="w-full mr-2 px-2 bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 border border-green-1 hover:border-transparent rounded md:w-auto"
                      >
                        Join
                      </button>
                    ) : (
                      <div>Joining...</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    )
  )
}

export default JoinVoyage
