import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Entity } from 'drupal-jsonapi-client'
import Downshift from 'downshift'
import { Link } from 'gatsby'

import '../../../base.css'
import { getAllTripsByUuid } from '../../../utils/trips'
import ConfirmDelete from '../ConfirmDelete'
import { navigate } from 'gatsby'

const capitalize = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1) || ''
}

const AMENITIES = [
  'Flights',
  'Accommodation',
  'Co-working',
  'Fun activities',
  'Learn a new skill',
]

const formReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      return { ...state, [action.name]: action.value }
    case 'ADD_TRIP':
      return {
        ...state,
        field_voyage_trips: [...state.field_voyage_trips, action.trip],
      }
    case 'REMOVE_TRIP':
      const index = state.field_voyage_trips.findIndex(
        item => item.id === action.tripID
      )
      return {
        ...state,
        field_voyage_trips: [
          ...state.field_voyage_trips.slice(0, index),
          ...state.field_voyage_trips.slice(index + 1),
        ],
      }
    case 'ADD_AMENITIES':
      return {
        ...state,
        field_amenities: [...state.field_amenities, action.item],
      }
    case 'REMOVE_AMENITIES':
      const i = state.field_amenities.findIndex(item => item === action.item)
      return {
        ...state,
        field_amenities: [
          ...state.field_amenities.slice(0, i),
          ...state.field_amenities.slice(i + 1),
        ],
      }
    case 'CLEAN_FORM':
      return { ...initialState }
    case 'PRELOAD_FORM':
      return { ...action.form }
    case 'LOADING':
      return { ...state, loading: action.value }
    default:
      return state
  }
}
const initialState = {
  title: '',
  body: '',
  field_voyage_trips: [],
  field_amenities: [],
  field_monthly_cost: '',
  field_seats_total: 0,
  field_total_days: '0',
  loading: false,
}

const FormVoyage = ({ uuid, voyageId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [formType, setFormType] = useState('create')
  const [state, dispatch] = useReducer(formReducer, initialState)
  const [messages, setMessages] = useState()

  const [trips, setTrips] = useState()
  useEffect(() => {
    ;(async () => {
      const trips = await getAllTripsByUuid({ uuid })
      setTrips(trips.map(t => ({ entityUuid: t.entityUuid, name: t.title })))
    })()
  }, [uuid])

  useEffect(() => {
    if (voyageId && voyageId !== 'new') {
      ;(async () => {
        const voyageEdit = await Entity.Load('node', 'voyage', voyageId)
        dispatch({
          type: 'PRELOAD_FORM',
          form: {
            ...initialState,
            id: voyageEdit.entityUuid,
            title: voyageEdit.title,
            body: voyageEdit.body.value,
            field_amenities: voyageEdit.field_amenities,
            field_monthly_cost: voyageEdit.field_monthly_cost,
            field_seats_total: voyageEdit.field_seats_total,
          },
        })

        for (const trip of voyageEdit.field_voyage_trips.data) {
          const tripRequest = await Entity.Load('node', 'trip', trip.id)
          dispatch({
            type: 'ADD_TRIP',
            trip: {
              id: trip.id,
              name: tripRequest._attributes.title,
              type: 'node--trip',
            },
          })
        }
        setFormType('update')
      })()
    }
  }, [voyageId])

  const onChangeInput = evt => {
    const { name, value } = evt.target
    dispatch({ type: 'CHANGE_INPUT', name, value })
  }

  const onSubmit = async evt => {
    dispatch({ type: 'LOADING', value: true })
    evt.preventDefault()

    const voyage = new Entity('node', 'voyage')

    if (formType === 'update') {
      voyage.entityUuid = voyageId
    }

    voyage.setAttribute('title', state.title)
    voyage.setAttribute('body', {
      value: state.body,
      format: 'basic_html',
    })
    voyage.setRelationship('field_voyage_trips', {
      data: state.field_voyage_trips.map(trip => ({
        id: trip.id,
        type: trip.type,
      })),
    })

    voyage.setAttribute('field_amenities', state.field_amenities)
    voyage.setAttribute('field_monthly_cost', state.field_monthly_cost)
    voyage.setAttribute('field_seats_total', state.field_seats_total)

    try {
      dispatch({ type: 'LOADING', value: true })
      await voyage.save()
      dispatch({ type: 'LOADING', value: false })

      if (formType === 'create') {
        setMessages({
          show: true,
          type: 'success',
          message: 'Thank you for creating your Voyage in SkillSailors.',
        })
        dispatch({ type: 'CLEAN_FORM' })
      } else {
        setMessages({
          show: true,
          type: 'success',
          message: 'Your trip information has been updated.',
        })
      }
    } catch (ex) {
      setMessages({
        show: true,
        type: 'error',
        message: 'An unexpected error occurred, please try again later.',
      })
      dispatch({ type: 'LOADING', value: false })
    }
  }

  const onDelete = async () => {
    const voyageDelete = await Entity.Load('node', 'voyage', state.id)
    if (!voyageDelete) {
      setModalIsOpen(false)
    }
    await voyageDelete.delete()
    setModalIsOpen(false)
    navigate('/admin/voyage-dashboard')
  }

  return (
    <div className="w-full flex justify-center">
      <ConfirmDelete
        isOpen={modalIsOpen}
        onDelete={onDelete}
        onCancel={() => {
          setModalIsOpen(false)
        }}
      />
      <div className="container">
        <div className="p-8">
          <h1 className="text-center text-xl font-bold lg:text-3xl">
            Let's {formType} your voyage
          </h1>
        </div>
        {messages && messages.show && (
          <div
            className={`w-full px-2 py-4 flex ${
              messages.type === 'success' ? 'bg-green-200' : 'bg-red-200'
            }`}
          >
            <p className="font-bold w-11/12">{messages.message}</p>
            <button
              onClick={() => {
                setMessages({})
              }}
              className="h-full text-md w-1/12 hover:text-bold"
            >
              x
            </button>
          </div>
        )}
        <div className="pt-4">
          <form
            onSubmit={onSubmit}
            action="/"
            className="w-full px-2 shadow-lg overflow-hidden"
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Title
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  id="voyage-title"
                  type="text"
                  name="title"
                  value={state['title']}
                  placeholder="Your awesome Voyage"
                  onChange={onChangeInput}
                  required
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-description"
                >
                  Description
                </label>
                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  id="voyage-description"
                  type="text"
                  placeholder="Tell us about your voyage."
                  rows={5}
                  name="body"
                  value={state['body']}
                  onChange={onChangeInput}
                  required
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-incoming"
                >
                  Incoming trips
                </label>

                {trips && trips.length > 0 ? (
                  <Downshift
                    onChange={selected => {
                      if (selected) {
                        dispatch({
                          type: 'ADD_TRIP',
                          trip: {
                            id: selected.entityUuid,
                            name: selected.name,
                            type: 'node--trip',
                          },
                        })
                      }
                    }}
                    itemToString={item => (item ? item.name : '')}
                  >
                    {({
                      getItemProps,
                      getMenuProps,
                      inputValue,
                      isOpen,
                      getInputProps,
                      clearSelection,
                    }) => (
                      <div>
                        <input
                          className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                          id="voyage-incoming"
                          type="text"
                          placeholder="What trips does it include?"
                          {...getInputProps({
                            onChange: e => {
                              if (e.target.value === '') {
                                clearSelection()
                              }
                            },
                          })}
                        />
                        <ul
                          {...getMenuProps()}
                          className={`w-full border ${
                            isOpen ? 'block' : 'hidden'
                          } `}
                        >
                          {isOpen
                            ? trips
                                .filter(item => {
                                  const { name = '' } = item

                                  return name
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                })
                                .map((item, index) => (
                                  <li
                                    className="py-1 hover:bg-gray-200 cursor-pointer px-2"
                                    {...getItemProps({
                                      key: item.entityUuid,
                                      index,
                                      item,
                                    })}
                                  >
                                    {item.name}
                                  </li>
                                ))
                            : null}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                ) : (
                  <p className="font-bold text-gray-700">
                    You have no trips, you can create one{' '}
                    <Link
                      className="underline text-green-1"
                      to={'/admin/trips/new'}
                    >
                      here
                    </Link>
                    .
                  </p>
                )}
              </div>
              {state.field_voyage_trips.length > 0 && (
                <div className="w-full px-3 mb-6">
                  <ul className="pl-2 w-full">
                    {state.field_voyage_trips.map(trip => {
                      return (
                        <li
                          className="py-1 flex items-center content-center"
                          key={trip.id}
                        >
                          <span
                            onClick={() => {
                              dispatch({
                                type: 'REMOVE_TRIP',
                                tripID: trip.id,
                              })
                            }}
                            className="pr-2 hover:text-red-500 cursor-pointer"
                          >
                            <svg
                              className="w-4 hover:fill-red"
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 59 59"
                            >
                              <g>
                                <path d="M29.5,51c0.552,0,1-0.447,1-1V17c0-0.553-0.448-1-1-1s-1,0.447-1,1v33C28.5,50.553,28.948,51,29.5,51z" />
                                <path d="M19.5,51c0.552,0,1-0.447,1-1V17c0-0.553-0.448-1-1-1s-1,0.447-1,1v33C18.5,50.553,18.948,51,19.5,51z" />
                                <path d="M39.5,51c0.552,0,1-0.447,1-1V17c0-0.553-0.448-1-1-1s-1,0.447-1,1v33C38.5,50.553,38.948,51,39.5,51z" />
                                <path
                                  d="M52.5,6H38.456c-0.11-1.25-0.495-3.358-1.813-4.711C35.809,0.434,34.751,0,33.499,0H23.5c-1.252,0-2.31,0.434-3.144,1.289
                              C19.038,2.642,18.653,4.75,18.543,6H6.5c-0.552,0-1,0.447-1,1s0.448,1,1,1h2.041l1.915,46.021C10.493,55.743,11.565,59,15.364,59
                              h28.272c3.799,0,4.871-3.257,4.907-4.958L50.459,8H52.5c0.552,0,1-0.447,1-1S53.052,6,52.5,6z M21.792,2.681
                              C22.24,2.223,22.799,2,23.5,2h9.999c0.701,0,1.26,0.223,1.708,0.681c0.805,0.823,1.128,2.271,1.24,3.319H20.553
                              C20.665,4.952,20.988,3.504,21.792,2.681z M46.544,53.979C46.538,54.288,46.4,57,43.636,57H15.364
                              c-2.734,0-2.898-2.717-2.909-3.042L10.542,8h37.915L46.544,53.979z"
                                />
                              </g>
                            </svg>
                          </span>
                          <span className="text-gray-700 text-base tracking-none">
                            {trip.name}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <div className="w-full px-5 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-amenities"
                >
                  Amenities
                </label>
                {AMENITIES.map(item => {
                  return (
                    <label
                      key={item}
                      className="md:w-2/3 block text-gray-500 font-bold"
                    >
                      <input
                        className="mr-2 leading-tight"
                        type="checkbox"
                        name={item}
                        checked={state.field_amenities.indexOf(item) !== -1}
                        value={item}
                        onChange={evt => {
                          const item = evt.target.value
                          if (evt.target.checked) {
                            dispatch({ type: 'ADD_AMENITIES', item })
                          } else {
                            dispatch({ type: 'REMOVE_AMENITIES', item })
                          }
                        }}
                      />
                      <span
                        className={`text-sm ${
                          state.field_amenities.indexOf(item) !== -1
                            ? 'text-gray-700'
                            : ''
                        } `}
                      >
                        {item}
                      </span>
                    </label>
                  )
                })}
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-cost"
                >
                  Available seats
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  id="voyage-cost"
                  type="number"
                  placeholder="How many seats do you have available?"
                  min="0"
                  step="any"
                  value={state['field_seats_total']}
                  name="field_seats_total"
                  onChange={onChangeInput}
                  required
                />
              </div>
              <div className="w-full md:w-2/4 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-cost"
                >
                  Monthly rate
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  id="voyage-cost"
                  type="number"
                  placeholder="How much do sailors pay per month?"
                  min="0"
                  step="any"
                  value={state['field_monthly_cost']}
                  name="field_monthly_cost"
                  onChange={onChangeInput}
                  required
                />
              </div>

              <div className="w-full px-3 mb-6 flex flex-col md:flex-row md:justify-between md:items-end">
                <button
                  type="submit"
                  className="w-full bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 px-4 border border-green-1 hover:border-transparent rounded mb-4 md:w-auto md:mb-0"
                >
                  {state.loading ? (
                    formType === 'create' ? (
                      'Saving Voyage ...'
                    ) : (
                      'Updating Voyage ...'
                    )
                  ) : (
                    <span>{capitalize(formType)} your Voyage</span>
                  )}
                </button>
                {formType !== 'create' && (
                  <button
                    onClick={() => {
                      setModalIsOpen(true)
                    }}
                    type="button"
                    className="w-full bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded md:w-auto"
                  >
                    Delete Voyage
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

FormVoyage.propTypes = {
  uuid: PropTypes.string.isRequired,
}

export default FormVoyage
