import React, { useEffect, useState, useRef, useReducer } from 'react'
import {
  GlobalClient,
  Entity,
  Filter,
  File as FileEntity,
} from 'drupal-jsonapi-client'

import { navigate } from 'gatsby'
import { DrupalImage } from 'react-drupal'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { CountryRegionData } from 'react-country-region-selector'
import Select from 'react-select'
import moment from 'moment'
import { encode, decode } from '../../../utils/textToHtml'

import './styles.css'
import '../DrupalImage/styles.css'
import 'react-day-picker/lib/style.css'
import Seo from '../Seo'
import ConfirmDelete from '../ConfirmDelete'

// https://github.com/Desarol/skillsailors/issues/225#issuecomment-512536556
const IMAGE_UPLOAD_LIMIT = 6

const initialState = {
  images: {
    field_image: [],
    field_accomodation_images: [],
    field_coworking_images: [],
    field_activities_images: [],
  },
  errors: {
    field_accomodation_images: null,
    field_coworking_images: null,
    field_activities_images: null,
  },
  hasError: false,
}

const imagesReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_IMAGE':
      return {
        ...state,
        images: {
          ...state.images,
          [action.field_name]: [
            ...state.images[action.field_name],
            action[action.field_name],
          ],
        },
      }

    case 'REMOVE_IMAGE':
      return {
        ...state,
        images: {
          ...state.images,
          [action.field_name]: state.images[action.field_name].filter(
            ({ id }) => id !== action.id
          ),
        },
      }
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field_name]: action.errorMessage,
        },
      }
    default:
      return state
  }
}

const FormTrip = ({ jwt, uuid, tripId }) => {
  const [stateImages, dispatch] = useReducer(imagesReducer, initialState)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const countries = useRef(
    CountryRegionData.map(([countryName, countrySlug]) => {
      return { value: countrySlug, label: countryName }
    })
  )

  const [fetched, setFetched] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [trip, setTrip] = useState(new Entity('node', 'trip'))
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [tripAccommInfo, setTripAccommInfo] = useState('')
  const [tripArrivalInfo, setTripArrivalInfo] = useState('')
  const [tripCoworkingInfo, setTripCoworkingInfo] = useState('')
  const [tripActivitiesInfo, setTripActivitiesInfo] = useState('')
  const [tripIntroduction, setTripIntroduction] = useState('')
  const [addressFields, setAddressFields] = useState({
    countryCode: '',
    administrativeAreas: '',
    administrativeArea: '',
    locality: '',
  })

  const imageUploadHandler = async ({ field_name, image }) => {
    const file = await FileEntity.Upload(
      image,
      null,
      'node',
      'trip',
      field_name
    )
    dispatch({
      type: 'ADD_IMAGE',
      field_name,
      [field_name]: {
        id: file.entityUuid,
        name: file.filename,
        url: `${GlobalClient.baseUrl}${file.uri.url}`,
      },
    })
  }

  useEffect(() => {
    if (!fetched && typeof window !== 'undefined') {
      ;(async () => {
        setFetched(true)
        const trips = await Entity.LoadMultiple({
          entityType: 'node',
          entityBundle: 'trip',
          filter: new Filter({
            identifier: 'ids',
            path: 'id',
            value: tripId,
          }),
          include: [
            'field_image',
            'field_accomodation_images',
            'field_coworking_images',
            'field_activities_images',
          ],
          limit: 1,
        })

        const fetchedTrip = trips ? trips.shift() : null
        if (fetchedTrip) {
          const {
            field_address_1,
            field_date,
            field_end_date,
            field_accommodation_info,
            field_arrival_info,
            field_coworking_info,
            field_activities_info,
            field_trip_introduction,
          } = fetchedTrip

          setTrip(fetchedTrip)

          const fetchedImages = await fetchedTrip.expand('field_image')
          const fetchedAcommodationImages = await fetchedTrip.expand(
            'field_accomodation_images'
          )
          const fetchedCoworkingImages = await fetchedTrip.expand(
            'field_coworking_images'
          )

          const fetchedActivitiesImages = await fetchedTrip.expand(
            'field_activities_images'
          )

          fetchedImages.forEach(tripImage => {
            dispatch({
              type: 'ADD_IMAGE',
              field_name: 'field_image',
              field_image: {
                id: tripImage.entityUuid,
                name: tripImage.filename,
                url: `${GlobalClient.baseUrl}${tripImage.uri.url}`,
              },
            })
          })

          fetchedAcommodationImages.forEach(accomodationImage => {
            dispatch({
              type: 'ADD_IMAGE',
              field_name: 'field_accomodation_images',
              field_accomodation_images: {
                id: accomodationImage.entityUuid,
                name: accomodationImage.filename,
                url: `${GlobalClient.baseUrl}${accomodationImage.uri.url}`,
              },
            })
          })

          fetchedCoworkingImages.forEach(coworkingImage => {
            dispatch({
              type: 'ADD_IMAGE',
              field_name: 'field_coworking_images',
              field_coworking_images: {
                id: coworkingImage.entityUuid,
                name: coworkingImage.filename,
                url: `${GlobalClient.baseUrl}${coworkingImage.uri.url}`,
              },
            })
          })

          fetchedActivitiesImages.forEach(activitiesImage => {
            dispatch({
              type: 'ADD_IMAGE',
              field_name: 'field_activities_images',
              field_activities_images: {
                id: activitiesImage.entityUuid,
                name: activitiesImage.filename,
                url: `${GlobalClient.baseUrl}${activitiesImage.uri.url}`,
              },
            })
          })

          setTripAccommInfo(
            field_accommodation_info
              ? decode(field_accommodation_info.value)
              : ''
          )
          setTripArrivalInfo(
            field_arrival_info ? decode(field_arrival_info.value) : ''
          )
          setTripCoworkingInfo(
            field_coworking_info ? decode(field_coworking_info.value) : ''
          )
          setTripActivitiesInfo(
            field_activities_info ? decode(field_activities_info.value) : ''
          )
          setTripIntroduction(
            field_trip_introduction ? decode(field_trip_introduction.value) : ''
          )
          setDate(moment(field_date).format('YYYY-MM-DD'))
          setEndDate(moment(field_end_date).format('YYYY-MM-DD'))

          if (field_address_1) {
            setAddressFields({
              countryCode: field_address_1.country_code,
              administrativeArea: field_address_1.administrative_area,
              administrativeAreas: setRegions({
                value: field_address_1.country_code,
                label: '',
              }),
              locality: field_address_1.locality,
            })
          }
        }
      })()
    }
    // eslint-disable-next-line
  }, [jwt])

  const onSubmit = async e => {
    let isValid = true
    e.preventDefault()
    setSubmitting(true)

    const keys = [
      'field_accomodation_images',
      'field_coworking_images',
      'field_activities_images',
    ]

    for (let index = 0; index < keys.length; index++) {
      if (stateImages.images[keys[index]].length < 3) {
        dispatch({
          type: 'SET_ERROR',
          field_name: keys[index],
          errorMessage: 'you must upload at least 3 photos',
        })
        isValid = false
        setSubmitting(false)
        break
      } else {
        dispatch({
          type: 'SET_ERROR',
          field_name: keys[index],
          errorMessage: null,
        })
      }
    }

    if (isValid) {
      const formData = new FormData(e.target)
      const newTrip = trip.copy()
      newTrip.entityUuid = trip.entityUuid
      // Have to use setAttribute / setRelationship here
      // because the Entity type is not defined
      // You might want to create a "Trip" which extends Entity
      // so that you can provide default values for fields
      newTrip.setAttribute('title', formData.get('trip-title'))
      newTrip.setRelationship('field_image', {
        data: stateImages.images.field_image.map(image => ({
          type: 'file--file',
          id: image.id,
        })),
      })

      newTrip.setRelationship('field_accomodation_images', {
        data: stateImages.images.field_accomodation_images.map(image => ({
          type: 'file--file',
          id: image.id,
        })),
      })

      newTrip.setRelationship('field_activities_images', {
        data: stateImages.images.field_activities_images.map(image => ({
          type: 'file--file',
          id: image.id,
        })),
      })

      newTrip.setRelationship('field_coworking_images', {
        data: stateImages.images.field_coworking_images.map(image => ({
          type: 'file--file',
          id: image.id,
        })),
      })

      newTrip.setAttribute('field_address_1', {
        country_code: addressFields.countryCode,
        administrative_area: addressFields.administrativeArea,
        locality: addressFields.locality,
      })

      newTrip.setAttribute('field_date', date)
      newTrip.setAttribute('field_end_date', endDate)
      // newTrip.setAttribute('field_cost', formData.get('trip-cost'))

      newTrip.setAttribute('field_accommodation_info', {
        value: encode(tripAccommInfo),
        format: 'basic_html',
      })
      newTrip.setAttribute('field_arrival_info', {
        value: encode(tripArrivalInfo),
        format: 'basic_html',
      })
      newTrip.setAttribute('field_coworking_info', {
        value: encode(tripCoworkingInfo),
        format: 'basic_html',
      })
      newTrip.setAttribute('field_activities_info', {
        value: encode(tripActivitiesInfo),
        format: 'basic_html',
      })
      newTrip.setAttribute('field_trip_introduction', {
        value: encode(tripIntroduction),
        format: 'basic_html',
      })

      const response = await newTrip.save()
      const savedTrip = Entity.FromResponse(response.data.data)
      setTrip(savedTrip)
      setSubmitting(false)
      navigate('/admin/voyage-dashboard')
    }
  }

  function setRegions(country) {
    let regions = ''
    const countriesWithRegions = ['CA', 'US', 'AU', 'AR']

    if (countriesWithRegions.includes(country.value)) {
      CountryRegionData.forEach(i => {
        if (i[1] === country.value) {
          regions = i
        }
      })
      regions = regions[2].split('|').map(regionPair => {
        const [regionName, regionShortCode = ''] = regionPair.split('~')
        return { value: regionShortCode, label: regionName }
      })
    }
    return regions
  }

  function selectCountry(country) {
    let regions = setRegions(country)

    setAddressFields({
      ...addressFields,
      countryCode: country.value,
      administrativeAreas: regions,
      administrativeArea: '',
    })
  }

  function onChangeField(field, value) {
    setAddressFields({ ...addressFields, [field]: value })
  }

  const deleteTrip = async () => {
    if (!trip) {
      setModalIsOpen(false)
    }

    await trip.delete()
    setModalIsOpen(false)
    navigate('/admin/voyage-dashboard')
  }

  return (
    <div className="w-full flex justify-center">
      <Seo title="Manage Trips" />
      <ConfirmDelete
        onDelete={() => {
          deleteTrip()
        }}
        onCancel={() => {
          setModalIsOpen(false)
        }}
        isOpen={modalIsOpen}
      />
      <div className="container">
        <div className="p-8">
          <h1 className="text-center text-xl font-bold lg:text-3xl">
            Let's {trip.entityUuid ? 'update' : 'create'} your trip
          </h1>
        </div>
        <div className="pt-4">
          <form onSubmit={onSubmit} className="w-full px-2 shadow-lg">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Title
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  type="text"
                  defaultValue={trip.title}
                  id="tripTitle"
                  name="trip-title"
                  placeholder="Your awesome Trip"
                  required
                />
              </div>
              <div
                className={`w-full px-3 mb-6 ${
                  addressFields.administrativeAreas ? 'md:w-1/3' : 'md:w-1/2'
                }`}
              >
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  City
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  type="text"
                  id="locality"
                  name="locality"
                  value={addressFields.locality}
                  onChange={e => onChangeField('locality', e.target.value)}
                  placeholder="Where will your trip be?"
                  required
                />
              </div>
              <div
                className={`w-full px-3 mb-6 ${
                  addressFields.administrativeAreas ? 'md:w-1/3' : 'md:w-1/2'
                }`}
              >
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Country
                </label>

                <Select
                  classNamePrefix="react-select"
                  className="react-select-container"
                  name={'country-code'}
                  value={
                    addressFields.countryCode
                      ? countries.current.filter(
                          ({ value }) => value === addressFields.countryCode
                        )
                      : 'Select a Country'
                  }
                  onChange={selectCountry}
                  placeholder={'Select a Country'}
                  options={countries.current}
                />
              </div>
              {addressFields.administrativeAreas && (
                <div className="w-full px-3 mb-6 md:w-1/3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                    htmlFor="voyage-title"
                  >
                    State / Province
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    className="react-select-container"
                    name={'administrative-area'}
                    isDisabled={addressFields.countryCode !== '' ? false : true}
                    value={
                      addressFields.administrativeArea
                        ? addressFields.administrativeAreas.filter(
                            ({ value }) =>
                              value === addressFields.administrativeArea
                          )
                        : 'Select a Country'
                    }
                    onChange={selectedOption =>
                      setAddressFields({
                        ...addressFields,
                        administrativeArea: selectedOption.value,
                      })
                    }
                    placeholder={'Select a State'}
                    options={addressFields.administrativeAreas}
                  />
                </div>
              )}
              <div className="w-full px-3 mb-6 md:w-1/2">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Start Date
                </label>

                <DayPickerInput
                  value={moment(date).format('YYYY-MM-DD')}
                  parseDate={parseDate}
                  placeholder={`${moment(new Date()).format('YYYY-MM-DD')}`}
                  formatDate={formatDate}
                  onDayChange={dateSelect => {
                    setDate(moment(dateSelect).format('YYYY-MM-DD'))
                  }}
                />
              </div>
              <div className="w-full px-3 mb-6 md:w-1/2">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  End Date
                </label>

                <DayPickerInput
                  value={moment(endDate).format('YYYY-MM-DD')}
                  parseDate={parseDate}
                  placeholder={`${moment(new Date()).format('YYYY-MM-DD')}`}
                  formatDate={formatDate}
                  onDayChange={dateSelect => {
                    setEndDate(moment(dateSelect).format('YYYY-MM-DD'))
                  }}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Trip Introduction
                </label>

                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  onChange={e => setTripIntroduction(e.target.value)}
                  value={tripIntroduction}
                />
              </div>
              <div className="w-full px-3 mb-6 imageUploader">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2 "
                  htmlFor="voyage-title"
                >
                  Trip Images (
                  <i>
                    Maximum upload{' '}
                    {IMAGE_UPLOAD_LIMIT - stateImages.images.field_image.length}
                  </i>
                  )
                </label>

                <DrupalImage
                  limit={IMAGE_UPLOAD_LIMIT}
                  images={stateImages.images['field_image']}
                  onDelete={id => {
                    FileEntity.Delete(id)
                    dispatch({
                      type: 'REMOVE_IMAGE',
                      id,
                      field_name: 'field_image',
                    })
                  }}
                  onUpload={async image => {
                    await imageUploadHandler({
                      field_name: 'field_image',
                      image,
                    })
                  }}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Accommodation Information
                </label>

                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  onChange={e => setTripAccommInfo(e.target.value)}
                  value={tripAccommInfo}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2 "
                  htmlFor="voyage-title"
                >
                  Accommodation Images (
                  <i>
                    Maximum upload{' '}
                    {IMAGE_UPLOAD_LIMIT -
                      stateImages.images.field_accomodation_images.length}
                  </i>
                  )
                </label>

                <DrupalImage
                  limit={IMAGE_UPLOAD_LIMIT}
                  images={stateImages.images['field_accomodation_images']}
                  onDelete={id => {
                    FileEntity.Delete(id)
                    dispatch({
                      type: 'REMOVE_IMAGE',
                      id,
                      field_name: 'field_accomodation_images',
                    })
                  }}
                  onUpload={async image => {
                    await imageUploadHandler({
                      field_name: 'field_accomodation_images',
                      image,
                    })
                  }}
                />
                {stateImages.errors.field_accomodation_images && (
                  <p className="text-red-500 font-bold">
                    {stateImages.errors.field_accomodation_images}
                  </p>
                )}
              </div>

              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Coworking Information
                </label>

                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  onChange={e => setTripCoworkingInfo(e.target.value)}
                  value={tripCoworkingInfo}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2 "
                  htmlFor="voyage-title"
                >
                  Coworking Images (
                  <i>
                    Maximum upload{' '}
                    {IMAGE_UPLOAD_LIMIT -
                      stateImages.images.field_coworking_images.length}
                  </i>
                  )
                </label>

                <DrupalImage
                  limit={IMAGE_UPLOAD_LIMIT}
                  images={stateImages.images['field_coworking_images']}
                  onDelete={id => {
                    FileEntity.Delete(id)
                    dispatch({
                      type: 'REMOVE_IMAGE',
                      id,
                      field_name: 'field_coworking_images',
                    })
                  }}
                  onUpload={async image => {
                    await imageUploadHandler({
                      field_name: 'field_coworking_images',
                      image,
                    })
                  }}
                />
                {stateImages.errors.field_coworking_images && (
                  <p className="text-red-500 font-bold">
                    {stateImages.errors.field_coworking_images}
                  </p>
                )}
              </div>

              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Activities Information
                </label>

                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  onChange={e => setTripActivitiesInfo(e.target.value)}
                  value={tripActivitiesInfo}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2 "
                  htmlFor="voyage-title"
                >
                  Activities Images (
                  <i>
                    Maximum upload{' '}
                    {IMAGE_UPLOAD_LIMIT -
                      stateImages.images.field_activities_images.length}
                  </i>
                  )
                </label>

                <DrupalImage
                  limit={IMAGE_UPLOAD_LIMIT}
                  images={stateImages.images['field_activities_images']}
                  onDelete={id => {
                    FileEntity.Delete(id)
                    dispatch({
                      type: 'REMOVE_IMAGE',
                      id,
                      field_name: 'field_activities_images',
                    })
                  }}
                  onUpload={async image => {
                    await imageUploadHandler({
                      field_name: 'field_activities_images',
                      image,
                    })
                  }}
                />
                {stateImages.errors.field_activities_images && (
                  <p className="text-red-500 font-bold">
                    {stateImages.errors.field_activities_images}
                  </p>
                )}
              </div>
              <div className="w-full px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-title"
                >
                  Arrival Information
                </label>

                <textarea
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  onChange={e => setTripArrivalInfo(e.target.value)}
                  value={tripArrivalInfo}
                />
              </div>
              
              <div className="w-full px-3 mb-6 flex flex-col md:flex-row md:justify-between md:items-end">
                <button
                  disabled={submitting}
                  className={`w-full bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 px-4 border border-green-1 hover:border-transparent rounded md:w-auto ${
                    submitting ? 'opacity-50 cursor-not-allowed text-white' : ''
                  }`}
                >
                  {submitting
                    ? trip.entityUuid
                      ? 'Updating trip'
                      : 'Saving Trip'
                    : trip.entityUuid
                    ? 'Update trip'
                    : 'Create trip'}
                </button>
                {trip.entityUuid && (
                  <button
                    onClick={() => {
                      setModalIsOpen(true)
                    }}
                    type="button"
                    className="w-full bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded md:w-auto"
                  >
                    Delete trip
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FormTrip
