import React, { useState } from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import UserProfile from '../components/UserProfile'
import FormTrip from '../components/FormTrip'
import FormVoyage from '../components/FormVoyage'
import VoyageAdmin from '../components/VoyageAdmin'
import Layout from '../components/Layout'
import { GlobalClient, Entity, Filter, User } from 'drupal-jsonapi-client'
import {
  DrupalAuthenticationProvider,
  DrupalLogin,
  DrupalRegister,
} from 'react-drupal'
import idx from 'idx'
import './styles/register.css'
import FormJoinVoyage from '../components/FormJoinVoyage'
import ListPendingRequests from '../components/ListPendingRequests'
import FormStory from '../components/FormStory'

const Admin = ({ location }) => {
  GlobalClient.baseUrl = 'https://dev-skillsailors.pantheonsite.io'

  const [authenticated, setAuthenticated] = useState(false)

  const redirectedFrom = idx(location, _ => _.state.from)
  const pathPage = idx(location, _ => _.state.pathPage)

  const onCreateSession = async (user, session) => {
    GlobalClient.authorization = `Bearer ${session.jwt}`
    // Need to filter by UID not UUID so can't do Entity.Load
    const users = await Entity.LoadMultiple({
      entityType: 'user',
      entityBundle: 'user',
      filter: new Filter({
        identifier: 'ids',
        path: 'uid',
        value: user.current_user.uid,
      }),
    })
    return {
      ...session,
      user: {
        data: { ...users[0]._serialize().data, id: users[0].entityUuid },
      },
    }
  }

  const handleChange = ({ jwt }) => {
    GlobalClient.authorization = jwt ? `Bearer ${jwt}` : null

    if (jwt) {
      if (redirectedFrom) {
        navigate(redirectedFrom)
      } else {
        navigate('/admin/profile')
      }
    } else if (!pathPage) {
      navigate('/admin')
    } else {
      navigate(pathPage)
    }
  }

  return (
    <Layout>
      <DrupalAuthenticationProvider
        onInit={({ jwt, user }) => {
          setAuthenticated(true)
          GlobalClient.authorization = jwt ? `Bearer ${jwt}` : null
        }}
        onChange={handleChange}
      >
        {({ jwt, user }) =>
          authenticated ? (
            jwt ? (
              <React.Fragment>
                <Router>
                  <FormVoyage
                    uuid={user.data.id}
                    path="/admin/voyage/:voyageId"
                  />
                  <FormTrip
                    path="/admin/trips/:tripId"
                    uuid={user.data.id}
                    jwt={jwt}
                  />
                  <VoyageAdmin
                    path="/admin/voyage-dashboard"
                    uuid={user.data.id}
                    jwt={jwt}
                  />
                  <UserProfile
                    path="/admin/profile"
                    username={user.data.username}
                    uuid={user.data.id}
                    jwt={jwt}
                  />

                  <FormJoinVoyage
                    path="/admin/join-voyage/:voyageID"
                    uuid={user.data.id}
                    location={location}
                  />
                  <ListPendingRequests
                    path="/admin/pending-requests"
                    uuid={user.data.id}
                    jwt={jwt}
                  />
                  <FormStory path="/admin/story/:storyID" />
                </Router>
              </React.Fragment>
            ) : (
              <div className="w-full flex  justify-center">
                <div className="container">
                  {redirectedFrom && (
                    <div>Please signup or register to join this trip!</div>
                  )}
                  <div className="flex flex-col md:flex-row md:justify-center">
                    <div className="w-full relative shadow my-4 p-4 md:w-1/2 md:my-0 register-block register-margin-r">
                      <img alt="Login" className="register-image-lg" src="/captain-hat.png"/>
                      <h2 className="text-2xl py-2 text-gray-700">Login</h2>
                      <div className="column">
                        <DrupalLogin
                          onLogin={User.Login}
                          onCreateSession={onCreateSession}
                          expireAfterMinutes={60}
                        />
                      </div>
                    </div>

                    <div className="w-full shadow p-4 md:w-1/2 md:ml-2 register-block register-margin-l">
                      <img alt="Register" className="register-image" src="/sailor.png"/>
                      <h2 className="text-2xl py-2 text-gray-700">Register</h2>
                      <div className="column">
                        <DrupalRegister
                          onLogin={User.Login}
                          onRegister={User.Register}
                          onCreateSession={onCreateSession}
                          expireAfterMinutes={60}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null
        }
      </DrupalAuthenticationProvider>
    </Layout>
  )
}

export default Admin
