import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Entity, Filter } from 'drupal-jsonapi-client'
import ContentLoader from 'react-content-loader'
import './styles.css'
import Tabs from '../Tabs'
import ConfirmDelete from '../ConfirmDelete'

const FormTrip = ({ uuid, jwt }) => {
  const [current, setCurrent] = useState({ type: '', uuid: '' })
  const [voyages, setVoyages] = useState([])
  const [trips, setTrips] = useState([])
  const [stories, setStories] = useState([])
  const [loading, setLoading] = useState(true)
  const [overView, setOverView] = useState({ voyage: 0, trips: 0 })
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    ;(async () => {
      const storiesRequest = await Entity.LoadMultiple({
        entityType: 'node',
        entityBundle: 'story',
        filter: new Filter({
          identifier: 'ids',
          path: 'uid.id',
          value: uuid,
        }),
      })

      setStories(storiesRequest)

      const tripRequest = await Entity.LoadMultiple({
        entityType: 'node',
        entityBundle: 'trip',
        filter: new Filter({
          identifier: 'ids',
          path: 'uid.id',
          value: uuid,
        }),
      })

      const tripsResponse = tripRequest.map(trip => ({
        id: trip.entityUuid,
        title: trip._attributes.title,
      }))

      setTrips(tripsResponse)

      const voyageRequest = await Entity.LoadMultiple({
        entityType: 'node',
        entityBundle: 'voyage',
        filter: new Filter({
          identifier: 'ids',
          path: 'uid.id',
          value: uuid,
        }),
      })

      let voyages = voyageRequest
        .map(voyage => ({
          id: voyage.entityUuid,
          title: voyage._attributes.title,
          trips: voyage._relationships.field_voyage_trips.data.map(t => t.id),
          created: voyage._attributes.created,
        }))
        .sort((a, b) => new Date(b.created) - new Date(a.created))

      for (const voyage of voyages) {
        let voyageTrips = []
        for (const trip of voyage.trips) {
          const tRequest = await Entity.LoadMultiple({
            entityType: 'node',
            entityBundle: 'trip',
            filter: new Filter({
              identifier: 'ids',
              path: 'id',
              value: trip,
            }),
          })

          const t = tRequest.reduce((prev, next) => {
            prev.push({ id: next.entityUuid, title: next.title })
            return prev
          }, [])
          voyageTrips = [...voyageTrips, ...t]
        }

        voyage.trips = voyageTrips
      }

      setOverView({
        voyage: voyages.length,
        trips: tripsResponse.length,
        stories: storiesRequest.length,
      })
      setVoyages(voyages)
      setLoading(false)
    })()
  }, [uuid, current])

  const deleteAction = async ({ type, uuid }) => {
    const entity = await Entity.Load('node', type, uuid)
    if (!entity) {
      setModalIsOpen(false)
    }
    await entity.delete()
    setModalIsOpen(false)
    setCurrent({ type: '', uuid: '' })
  }

  return (
    <div className="w-full flex justify-center">
      <div className="container">
        <div className="p-8">
          <h1 className="text-center text-2xl font-bold lg:text-3xl">
            Start hosting coworking adventures
          </h1>
        </div>
        <div className="w-full flex flex-col justify-end md:flex-row">
          <div className="flex md:w-3/4 flex items-center md:border-b">
            <h2 className="text-xl my-2 text-gray-700 md:text-2xl md:my-0">
              Manage your trips and voyages
            </h2>
          </div>
          <div className="py-4 flex md:w-1/2 justify-center border-b md:justify-end">
            <Link
              className="w-full mr-2 px-2 bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 border border-green-1 hover:border-transparent rounded md:w-auto md:mx-1"
              to="/admin/story/new"
            >
              Add Story
            </Link>
            <Link
              className="w-full mr-2 px-2 bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 border border-green-1 hover:border-transparent rounded md:w-auto md:mx-1"
              to="/admin/voyage/new"
            >
              Add Voyage
            </Link>
            <Link
              className="w-full ml-2 px-2 bg-transparent hover:bg-green-1 text-green-1 font-semibold hover:text-white py-2 border border-green-1 hover:border-transparent rounded md:w-auto md:mx-1"
              to="/admin/trips/new"
            >
              Add Trip
            </Link>
          </div>
        </div>

        <div className="w-full pt-4">
          {loading && (
            <div className="flex flex-col w-full md:flex-row">
              <div className="w-full py-2 hidden lg:block lg:w-1/4">
                <ContentLoader
                  height={160}
                  width={400}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="60" rx="3" ry="3" width="120" height="6" />
                  <rect x="0" y="80" rx="3" ry="3" width="220" height="6" />
                  <rect x="0" y="100" rx="3" ry="3" width="220" height="6" />
                </ContentLoader>
              </div>
              <div className="w-full lg:w-3/4">
                <ContentLoader
                  height={400}
                  width={400}
                  speed={2}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="10" rx="3" ry="3" width="460" height="30" />
                  <rect x="0" y="45" rx="3" ry="3" width="460" height="30" />
                </ContentLoader>
              </div>
            </div>
          )}
          {!loading && (
            <div className="w-full flex flex-col lg:flex-row">
              <div className="w-full py-2 lg:w-1/4">
                <h2 className="text-gray-700 mb-2">Dashboard</h2>

                <ul className="flex w-full rounded-md p-4 lg:bg-transparent lg:flex-col lg:p-0 lg:pt-4">
                  <li className="form__btm-margin w-1/2 text-center lg:text-left lg:w-full border-r md:border-none">
                    <span className="text-gray-700 font-bold">Voyages:</span>
                    <span className="block md:inline-block md:px-2">
                      {overView.voyage}
                    </span>
                  </li>
                  <li className="form__btm-margin w-1/2 text-center lg:text-left lg:w-full">
                    <span className="text-gray-700 font-bold">Trips:</span>
                    <span className="block md:inline-block md:px-2">
                      {overView.trips}
                    </span>
                  </li>
                  <li className="form__btm-margin w-1/2 text-center lg:text-left lg:w-full">
                    <span className="text-gray-700 font-bold">Stories:</span>
                    <span className="block md:inline-block md:px-2">
                      {overView.stories}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="lg:w-3/4 flex flex-col">
                <ConfirmDelete
                  onCancel={() => {
                    setModalIsOpen(false)
                  }}
                  onDelete={() => {
                    const { type, uuid } = current
                    deleteAction({ type, uuid })
                  }}
                  isOpen={modalIsOpen}
                />

                <Tabs>
                  <div label="Voyages">
                    {voyages &&
                      voyages.map(voyage => {
                        return (
                          <div
                            className="form__top-padding cursor-pointer flex items-center border-b"
                            key={voyage.id}
                          >
                            <div className="w-10/12 m-0 form__margin-right">{voyage.title}</div>
                            <div className="form__edit-links">
                              <Link
                                className="px-1 hover:underline"
                                to={`/admin/voyage/${voyage.id}`}
                              >
                                Edit
                              </Link>
                              <button
                                onClick={() => {
                                  setCurrent({
                                    type: 'voyage',
                                    uuid: voyage.id,
                                  })
                                  setModalIsOpen(true)
                                }}
                                className="px-1 hover:underline form__thin"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <div label="Trips">
                    {trips &&
                      trips.map(trip => {
                        return (
                          <div
                            className="form__top-padding cursor-pointer flex items-center border-b"
                            key={trip.id}
                          >
                            <div className="w-10/12 m-0 form__margin-right">{trip.title}</div>
                            <div className="form__edit-links">
                              <Link
                                className="px-1 hover:underline"
                                to={`/admin/trips/${trip.id}`}
                              >
                                Edit
                              </Link>
                              <button
                                onClick={() => {
                                  setCurrent({
                                    type: 'trip',
                                    uuid: trip.id,
                                  })
                                  setModalIsOpen(true)
                                }}
                                className="px-1 hover:underline form__thin"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <div label="Stories">
                    {stories &&
                      stories.map(story => {
                        return (
                          <div
                            className="form__top-padding cursor-pointer flex items-center border-b"
                            key={story.id}
                          >
                            <div className="w-10/12 m-0 form__margin-right">{story.title}</div>
                            <div className="form__edit-links">
                              <Link
                                className="px-1 hover:underline"
                                to={`/admin/story/${story.entityUuid}`}
                              >
                                Edit
                              </Link>
                              <button
                                onClick={() => {
                                  setCurrent({
                                    type: 'story',
                                    uuid: story.entityUuid,
                                  })
                                  setModalIsOpen(true)
                                }}
                                className="px-1 hover:underline form__thin"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormTrip
