import React, { useState, useEffect, createRef } from 'react'
import useForm from 'react-hook-form'
import Downshift from 'downshift'
import { Entity, GlobalClient, File as FileEntity } from 'drupal-jsonapi-client'
import { navigate } from 'gatsby'

import { getAllTrips } from '../../../utils/trips'
import CustomRichTextEditor, {
  createEmptyValue,
  createValueFromString,
} from '../../components/RichTextEditor'

import './styles.css'
import Seo from '../Seo'
import { DrupalImage } from 'react-drupal'
import ConfirmDelete from '../ConfirmDelete'

const uniqueID = () => {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

const readFile = file => {
  if (!file) return
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function(e) {
      let dataURL = e.target.result
      dataURL = dataURL.replace(';base64', `;name=${file.name};base64`)
      resolve({ file, dataURL, id: uniqueID() })
    }

    reader.readAsDataURL(file)
  })
}

const FormStory = ({ storyID }) => {
  const fileImage = createRef()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [image, setImage] = useState([])
  const [error, setError] = useState(false)
  const [trips, setTrips] = useState([])
  const [trip, setTrip] = useState(null)

  const [storyBody, setStoryBody] = useState(createEmptyValue)
  const [story] = useState(new Entity('node', 'story'))
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    if (storyID && storyID !== 'new') {
      ;(async () => {
        const storyRequest = await Entity.Load('node', 'story', storyID)
        const images = await storyRequest.expand('field_image')

        const [featurePhoto] = images

        setValue('title', storyRequest.title)

        setStoryBody(createValueFromString(storyRequest.body.processed, 'html'))
        setImage([
          {
            id: featurePhoto.entityUuid,
            url: `${GlobalClient.baseUrl}${featurePhoto.uri.url}`,
          },
          ...images.slice(1).map(i => ({
            id: i.entityUuid,
            url: `${GlobalClient.baseUrl}${i.uri.url}`,
          })),
        ])
      })()
    }
  }, [storyID, setValue])

  useEffect(() => {
    ;(async () => {
      const trips = await getAllTrips()
      setTrips(trips.map(t => ({ entityUuid: t.entityUuid, name: t.title })))
    })()
  }, [])

  const onPrincipalFileChange = async evt => {
    const files = evt.target.files
    if (files && files.length > 0) {
      setLoading(true)
      const file = files[0]
      const ff = await readFile(file)
      const fileUpload = await FileEntity.Upload(
        ff.file,
        null,
        'node',
        'story',
        'field_image'
      )
      setImage([
        {
          id: fileUpload.entityUuid,
          name: '',
          url: `${GlobalClient.baseUrl}${fileUpload.uri.url}`,
        },
        ...image,
      ])

      setLoading(false)
    }
  }

  const uploadPicture = () => {
    fileImage.current.click()
  }

  const removeImage = () => {
    setImage(image.slice(1))
  }

  const onSubmit = async data => {
    if (image && image.length === 0) {
      setError('Please upload an image.')
      return
    } else {
      setError(false)
    }
    setSubmitting(true)
    if (storyID === 'new') {
      const review = new Entity('node', 'story')
      review.setAttribute('title', data.title)
      review.setAttribute('body', {
        format: 'basic_html',
        value: storyBody.toString('html'),
      })

      review.setRelationship('field_image', {
        data: image.map(img => ({ type: 'file--file', id: img.id })),
      })

      review.setRelationship('field_trip_reference', {
        data: {
          type: 'node--trip',
          id: trip.entityUuid,
        },
      })

      await review.save()
      setSubmitting(false)
    } else {
      const storyEdit = story.copy()
      storyEdit.entityUuid = storyID
      storyEdit.setAttribute('title', data.title)
      storyEdit.setAttribute('body', {
        format: 'basic_html',
        value: storyBody.toString('html'),
      })

      storyEdit.setRelationship('field_image', {
        data: image.map(img => ({ type: 'file--file', id: img.id })),
      })

      await storyEdit.save()
      setSubmitting(false)
    }
    navigate('/admin/voyage-dashboard/')
  }
  const onDelete = async () => {
    const storyRequest = await Entity.Load('node', 'story', storyID)
    if (!storyRequest) {
      modalIsOpen(false)
    }
    await storyRequest.delete()
    navigate('admin/voyage-dashboard')
  }

  return (
    <div className="w-full flex justify-center">
      <Seo />
      <ConfirmDelete
        isOpen={modalIsOpen}
        onDelete={onDelete}
        onCancel={() => {
          setModalIsOpen(false)
        }}
      />
      <div className="container">
        <div className="p-8">
          <h1 className="text-center text-2xl font-bold lg:text-3xl">
            Write a story on your experience
          </h1>
        </div>
        <div className="pt-4">
          <form
            className="relative w-full px-2 shadow-lg overflow-hidden"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-wrap -mx-3  bg-gray-200">
              {image && image.length > 0 ? (
                <div className="w-full h-32 overflow-hidden relative lg:h-64">
                  <img alt="Story cover" className="top-0" src={image[0].url} />
                  <div className="flex justify-center p-2 absolute bottom-0">
                    <button
                      type="button"
                      onClick={removeImage}
                      className="z-50 text-white hover:underline"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <button
                    onClick={uploadPicture}
                    type="button"
                    className="w-full p-4 text-center  flex flex-col items-center content-center lg:w-1/3"
                  >
                    <img
                      alt="Upload file"
                      className="w-20"
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU4IDU4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1OCA1ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxnPgoJPHBhdGggZD0iTTU3LDZIMUMwLjQ0OCw2LDAsNi40NDcsMCw3djQ0YzAsMC41NTMsMC40NDgsMSwxLDFoNTZjMC41NTIsMCwxLTAuNDQ3LDEtMVY3QzU4LDYuNDQ3LDU3LjU1Miw2LDU3LDZ6IE01Niw1MEgyVjhoNTRWNTB6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8cGF0aCBkPSJNMTYsMjguMTM4YzMuMDcxLDAsNS41NjktMi40OTgsNS41NjktNS41NjhDMjEuNTY5LDE5LjQ5OCwxOS4wNzEsMTcsMTYsMTdzLTUuNTY5LDIuNDk4LTUuNTY5LDUuNTY5ICAgQzEwLjQzMSwyNS42NCwxMi45MjksMjguMTM4LDE2LDI4LjEzOHogTTE2LDE5YzEuOTY4LDAsMy41NjksMS42MDIsMy41NjksMy41NjlTMTcuOTY4LDI2LjEzOCwxNiwyNi4xMzhzLTMuNTY5LTEuNjAxLTMuNTY5LTMuNTY4ICAgUzE0LjAzMiwxOSwxNiwxOXoiIGZpbGw9IiMwMDAwMDAiLz4KCTxwYXRoIGQ9Ik03LDQ2YzAuMjM0LDAsMC40Ny0wLjA4MiwwLjY2LTAuMjQ5bDE2LjMxMy0xNC4zNjJsMTAuMzAyLDEwLjMwMWMwLjM5MSwwLjM5MSwxLjAyMywwLjM5MSwxLjQxNCwwczAuMzkxLTEuMDIzLDAtMS40MTQgICBsLTQuODA3LTQuODA3bDkuMTgxLTEwLjA1NGwxMS4yNjEsMTAuMzIzYzAuNDA3LDAuMzczLDEuMDQsMC4zNDUsMS40MTMtMC4wNjJjMC4zNzMtMC40MDcsMC4zNDYtMS4wNC0wLjA2Mi0xLjQxM2wtMTItMTEgICBjLTAuMTk2LTAuMTc5LTAuNDU3LTAuMjY4LTAuNzItMC4yNjJjLTAuMjY1LDAuMDEyLTAuNTE1LDAuMTI5LTAuNjk0LDAuMzI1bC05Ljc5NCwxMC43MjdsLTQuNzQzLTQuNzQzICAgYy0wLjM3NC0wLjM3My0wLjk3Mi0wLjM5Mi0xLjM2OC0wLjA0NEw2LjMzOSw0NC4yNDljLTAuNDE1LDAuMzY1LTAuNDU1LDAuOTk3LTAuMDksMS40MTJDNi40NDcsNDUuODg2LDYuNzIzLDQ2LDcsNDZ6IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="
                    />
                    {loading ? (
                      <p>Uploading photo, please wait a moment...</p>
                    ) : (
                      <p>Click to upload Image</p>
                    )}
                  </button>
                </div>
              )}
              <input
                className="hidden"
                type="file"
                name="picture"
                onChange={onPrincipalFileChange}
                ref={fileImage}
              />
            </div>

            <div className="mt-4 flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 lg:w-1/2">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="story-title"
                >
                  Title
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                  id="story-title"
                  type="text"
                  name="title"
                  placeholder="Your story title"
                  required
                  ref={register({ required: true })}
                />
              </div>

              {storyID && storyID === 'new' && (
                <div className="w-full px-3 mb-6 lg:w-1/2">
                  <label
                    className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                    htmlFor="voyage-incoming"
                  >
                    Trip
                  </label>
                  <Downshift
                    onChange={selection => {
                      setTrip(selection)
                    }}
                    itemToString={item => (item ? item.name : '')}
                  >
                    {({
                      getItemProps,
                      getMenuProps,
                      inputValue,
                      isOpen,
                      getInputProps,
                    }) => (
                      <div>
                        <input
                          className="appearance-none block w-full text-gray-700 border-2 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-green-1"
                          id="voyage-incoming"
                          type="text"
                          name="trip"
                          placeholder="What trip is your story attached to?"
                          {...getInputProps()}
                          ref={register}
                        />
                        <ul
                          {...getMenuProps()}
                          className={`absolute z-50 bg-white w-full border ${
                            isOpen ? 'block' : 'hidden'
                          } `}
                        >
                          {isOpen &&
                            trips
                              .filter(item => {
                                const { name = '' } = item
                                return name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                              })
                              .map((item, index) => (
                                <li
                                  className="py-1 hover:bg-gray-200 cursor-pointer px-2"
                                  {...getItemProps({
                                    key: item.entityUuid,
                                    index,
                                    item,
                                  })}
                                >
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                </div>
              )}
              <div className="w-full px-3 mb-6 ">
                <label
                  className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                  htmlFor="voyage-incoming"
                >
                  Story
                </label>
                <CustomRichTextEditor
                  value={storyBody}
                  onChange={setStoryBody}
                />
              </div>
              {image && image.length > 0 && (
                <div className="w-full px-3 mb-6 drupal-image">
                  <label
                    className="block uppercase tracking-wide text-gray-700 user-profile__label font-bold mb-2"
                    htmlFor="voyage-incoming"
                  >
                    adding more images
                  </label>

                  <DrupalImage
                    onDelete={id => {
                      FileEntity.Delete(id)
                      setImage(image.filter(img => img.id !== id))
                    }}
                    images={image.slice(1)}
                    limit={9}
                    onUpload={async img => {
                      const file = await FileEntity.Upload(
                        img,
                        null,
                        'node',
                        'story',
                        'field_image'
                      )

                      setImage([
                        ...image,
                        {
                          id: file.entityUuid,
                          name: file.filename,
                          url: `${GlobalClient.baseUrl}${file.uri.url}`,
                        },
                      ])
                    }}
                  />
                </div>
              )}
              <div className="w-full px-3 mb-6 flex  md:justify-start md:items-center flex-col md:flex-row">
                <button
                  disabled={loading || submitting}
                  className={`w-full bg-transparent hover:bg-green-1 text-green-1 mr-2 font-semibold hover:text-white py-2 px-4 border border-green-1 hover:border-transparent rounded md:w-auto md:mb-0 mb-2 ${
                    submitting ? 'opacity-50 cursor-not-allowed text-white' : ''
                  }`}
                >
                  {storyID && storyID === 'new'
                    ? 'Create Story'
                    : 'Update Story'}
                </button>
                {storyID && storyID !== 'new' && (
                  <button
                    onClick={() => {
                      setModalIsOpen(true)
                    }}
                    type="button"
                    className="w-full bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded md:w-auto"
                  >
                    Delete Story
                  </button>
                )}
                <div className="text-red-600 text-base font-semibold">
                  {error && error}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FormStory
