import { Entity, Filter } from 'drupal-jsonapi-client'

export const getAllTripsByUuid = async ({ uuid }) => {
  const trips = await Entity.LoadMultiple({
    entityType: 'node',
    entityBundle: 'trip',
    filter: new Filter({
      identifier: 'ids',
      path: 'uid.id',
      value: uuid,
    }),
    limit: 1,
  })

  return trips
}

export const getAllTrips = async () => {
  const trips = await Entity.LoadMultiple({
    entityType: 'node',
    entityBundle: 'trip',
  })

  return trips
}

export default { getAllTripsByUuid, getAllTrips }
