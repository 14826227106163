import React from 'react'
import RichTextEditor from 'react-rte'
import toolbarConfig from '../../../utils/editorconfig'

const CustomRichTextEditor = ({ onChange, value }) => {
  if (typeof window !== 'undefined') {
    return (
      <RichTextEditor
        onChange={onChange}
        value={value}
        toolbarConfig={toolbarConfig}
      />
    )
  }
  return null
}

export const createEmptyValue = () =>
  typeof window !== 'undefined' && RichTextEditor.createEmptyValue()
export const createValueFromString = (text, format) =>
  typeof window !== 'undefined' &&
  RichTextEditor.createValueFromString(text, format)

export default CustomRichTextEditor
