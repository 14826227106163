import React from 'react'
import usePendingRequestVoyage from '../../hooks/usePendingRequestVoyage'

const ListPendingRequests = ({ uuid }) => {
  const [
    fetching,
    voyageUserMap,
    acceptUser,
    declineUser,
  ] = usePendingRequestVoyage({
    userId: uuid,
  })

  return (
    <>
      <div className="w-full">
        {!fetching &&
          Object.keys(voyageUserMap).map(tripId => {
            const { voyage, users } = voyageUserMap[tripId]
            return users.map(user => (
              <div
                className="p-4 flex bg-gray-200 mt-2 shadown-lg flex flex-col md:flex-row"
                key={`${voyage.entityUuid}|${user.entityUuid}`}
              >
                <h2 className="text-2xl w-full md:w-10/12">
                  <span className="font-bold pr-2">{user.name}</span>
                  wants to join you on{' '}
                  <span className="font-bold">{voyage.title}!</span>
                </h2>
                <div className="w-full pt-4 flex justify-end w-full md:w-2/12">
                  <button
                    className="text-xl leading-relaxed underline px-2"
                    onClick={() => acceptUser(voyage, user)}
                  >
                    Accept
                  </button>
                  <button
                    className="text-xl leading-relaxed underline px-2"
                    onClick={() => declineUser(voyage, user)}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ))
          })}
      </div>
    </>
  )
}

export default ListPendingRequests
