export const encode = (text = '') => {
  const textToArray = text.split('\n')
  if ('pop' in textToArray) {
    return textToArray
      .map(text => (text.length > 0 ? `<p>${text}</p>` : ''))
      .join('')
  }
  return ''
}

export const decode = (text = '') => {
  const textToArray = text.split('<p>')
  if ('pop' in textToArray) {
    const textWithNewLine = textToArray.map(text => text.replace('</p>', '\n'))
    return textWithNewLine.join('')
  }
}
