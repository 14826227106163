import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this

    let className =
      'bg-white inline-block py-2 px-4 hover:text-gray-700 font-semibold cursor-pointer m-0 text-gray-500'

    if (activeTab === label) {
      className += ' border-l border-t border-r rounded-t text-gray-700'
    } else {
      className += ' border-b'
    }

    return (
      <li className="-mb-px mr-1" onClick={onClick}>
        <p className={className}>{label}</p>
      </li>
    )
  }
}

export default Tab
